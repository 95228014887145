import { useGeneral } from '@utils/general';
import { debounce } from '@utils/debounce';

export const useMenu = (): void => {
  const {closeOnEsc, getScrollbarWidth, trapFocus} = useGeneral();

  const headerElement = document.querySelector('.header') as HTMLElement;
  const menuElement = document.querySelector('.menu') as HTMLElement;
  const toggleButtonElement = document.querySelector('.menu-toggle') as HTMLButtonElement;
  const containerElement = document.querySelector('.menu__container') as HTMLDivElement;
  const backdropElement = document.querySelector('.menu__backdrop') as HTMLDivElement;
  const contentElement = document.querySelector('.menu__content') as HTMLDivElement;
  const menuHeaderElement = document.querySelector('.menu__header') as HTMLElement;
  const menuReturnButtonElement = document.querySelector('.menu__return-button') as HTMLButtonElement;
  const menuButtonElements = document.querySelectorAll('.menu__item > button') as NodeListOf<HTMLButtonElement>;
  let isOpen: boolean = false;
  let mobileMenuIsInitialized: boolean = false;
  let desktopMenuIsInitialized: boolean = false;
  let generalEventListenersInitialized: boolean = false;

  const setMenuState = (state: boolean): void => {
    isOpen = state;
    document.querySelector('.menu')?.classList.toggle('menu--open', state);
  }

  const initializeMobileMenu = (): void => {
    // Open the menu on click
    toggleButtonElement.addEventListener('click', toggleMobileMenuState);

    // Close the submenu on click
    menuReturnButtonElement.addEventListener('click', closeMobileSubMenu);

    // Open the submenu on click
    menuButtonElements.forEach((buttonElement: HTMLButtonElement): void => {
      buttonElement.addEventListener('click', openMobileSubMenu);
    });

    mobileMenuIsInitialized = true;
  };

  const initializeDesktopMenu = () => {
    menuButtonElements.forEach((buttonElement: HTMLButtonElement): void => {
      buttonElement.addEventListener('click', toggleDesktopSubMenu);
    });

    // If scrolling past the header, add class header--fixed
    window.addEventListener('scroll', debounce(() => {
        if (window.innerWidth <= 1200) {
          return;
        }
        if (window.scrollY > headerElement.clientHeight) {
          if (!headerElement.classList.contains('header--fixed')) {
            headerElement.classList.add('header--animate');

            setTimeout(() => {
              headerElement.classList.remove('header--animate');
              headerElement.classList.add('header--fixed');
              if (headerElement.classList.contains('header--static')) {
                document.body.classList.add('has-fixed-header');
              }
            }, 100);
          }
        } else {
          headerElement.classList.add('header--animate');
          setTimeout(() => {
            headerElement.classList.remove('header--animate');
            headerElement.classList.remove('header--fixed');
            document.body.classList.remove('has-fixed-header');
          }, 200);
        }
      }, 100)
    );

    desktopMenuIsInitialized = true;
  };

  const toggleMobileMenuState = (): void => {
    if (!isOpen) {
      openMobileMenu();
    } else {
      closeMobileMenu();
    }
  }

  const openMobileMenu = (): void => {
    containerElement.hidden = false;
    document.body.classList.add('has-full-screen-modal');

    offsetHeaderPositionByScrollbarWidth();

    setTimeout((): void => {
      backdropElement.classList.add('menu__backdrop--visible');
      contentElement.classList.add('menu__content--visible');
      menuHeaderElement.classList.add('menu__header--visible');
    }, 10);

    toggleMobileMenuButton(true);
    document.addEventListener(`keydown`, initializeTrapFocus);
    setHeightOfMenuContent();
    
    setMenuState(true);
  }

  const closeMobileMenu = (): void => {
    contentElement.classList.remove('menu__content--visible');
    menuHeaderElement.classList.remove('menu__header--visible');
    backdropElement.classList.remove('menu__backdrop--visible');
    document.body.classList.remove('has-full-screen-modal');
    
    closeMobileSubMenu();

    resetHeaderPosition();

    setTimeout((): void => {
      containerElement.hidden = true;
    }, 500);

    toggleMobileMenuButton(false);
    document.removeEventListener(`keydown`, initializeTrapFocus);

    setMenuState(false);
  }

  const hardCloseMobileSubMenus = () => {
    document.querySelectorAll('.menu__submenu').forEach(submenuElement => {
      submenuElement?.classList.remove('menu__submenu--active');
      submenuElement.classList.remove('menu__submenu--visible');
      menuHeaderElement.classList.remove('menu__header--visible');
      menuReturnButtonElement.classList.remove('menu__return-button--visible');
    });
  }

  const closeMobileMenuOnOutsideClick = (event: MouseEvent): void => {
    if (event.target instanceof Element) {
      let clickedElement: Element = event.target;

      if (clickedElement === backdropElement) {
        closeMobileMenu();
      }
    }
  }

  const closeDesktopMenuOnOutsideClick = (event: MouseEvent): void => {
    if (event.target instanceof Element) {
      let clickedElement: Element = event.target;

      let parentIsNotMenu = !clickedElement.closest(".menu__submenu-content-header, .menu__submenu-wrapper, .menu__item");
      let clickedElementIsNotMenu = false;

      "menu__submenu-content-header,menu__submenu-wrapper,menu__item,menu__item-text".split(',').forEach(menuClass => {
        clickedElementIsNotMenu = !clickedElement.classList.contains(menuClass);
      });

      if (parentIsNotMenu || clickedElementIsNotMenu) {
        toggleDesktopSubMenu(event);
      }
    }
  }

  const openMobileSubMenu = (event: Event) => {
    const buttonElement = event.target as HTMLButtonElement;
    let submenu = buttonElement.closest('.menu__item')?.querySelector('.menu__submenu');

    // Close all other submenus
    hardCloseMobileSubMenus();

    submenu?.classList.add('menu__submenu--visible');
    menuHeaderElement.classList.add('menu__header--visible');
    menuReturnButtonElement.classList.add('menu__return-button--visible');

    setTimeout(() => {
      submenu?.classList.add('menu__submenu--active');
      setHeightOfMenuContent();
    }, 1);
  }

  const toggleDesktopSubMenu = (event: Event | null = null) => {
    const buttonElement = event?.target as HTMLButtonElement;
    let submenu = buttonElement?.closest('.menu__item')?.querySelector('.menu__submenu');

    if (!submenu) {
      submenu = document.querySelector('.menu__item--active')?.querySelector('.menu__submenu');
    }

    if (submenu?.classList.contains('menu__submenu--visible')) {
      submenu?.classList.remove('menu__submenu--visible');
      document.querySelector('.menu__item--active')?.classList.remove('menu__item--active');
      document.body.classList.remove('has-full-screen-modal');
      setMenuState(false);
      resetHeaderPosition();
    } else {
      closeAllDesktopSubMenu();
      submenu?.classList.add('menu__submenu--visible');
      document.body.classList.add('has-full-screen-modal');
      submenu?.parentElement?.classList.add('menu__item--active');
      setMenuState(true);
      offsetHeaderPositionByScrollbarWidth();
    }
  }
  
  const setHeightOfMenuContent = () => {
    let menuList = contentElement?.querySelector('.menu__list') as HTMLElement;
    
    if (window.innerWidth > 1200) {
      menuList.style.height = "";
      return;
    }
    
    menuList.style.height = "0";
    menuList.style.height = contentElement.scrollHeight - 1 + 'px';
  }

  const closeMobileSubMenu = () => {
    document.querySelector('.menu__submenu--active')?.classList.remove('menu__submenu--active');
    menuReturnButtonElement.classList.remove('menu__return-button--visible');

    // Wait for the animation to finish before removing the submenu
    setTimeout(() => {
      document.querySelector('.menu__submenu--visible')?.classList.remove('menu__submenu--visible');
      setHeightOfMenuContent();
    }, 150);
  }

  const closeAllDesktopSubMenu = () => {
    document.querySelector('.menu__item--active')?.classList.remove('menu__item--active');

    document.querySelectorAll('.menu__submenu--visible').forEach(submenu => {
      submenu.classList.remove('menu__submenu--visible');
    });

    document.body.classList.remove('has-full-screen-modal');
  }

  const offsetHeaderPositionByScrollbarWidth = (): void => {
    let scrollbarWidth: number = getScrollbarWidth();

    // Only set the scrollbar width if it is not already set
    if (!document.documentElement.style.getPropertyValue('--scrollbar-width')) {
      document.documentElement.style.setProperty('--scrollbar-width', scrollbarWidth + "px");
    }
    // Otherwise, use the existing value
    else {
      scrollbarWidth = parseInt(document.documentElement.style.getPropertyValue('--scrollbar-width'));
    }

    if (!headerElement.classList.contains('header--static')) {
    headerElement.style.marginRight = `-${scrollbarWidth}px`;
    }

    // If there is a visible scrollbar, add a border to the body to prevent content from shifting. 
    // Also add 10px to the height to account for pixel-ceiling-rounding.
    if (document.body.scrollHeight > window.innerHeight + 10) {
      document.body.style.borderRight = `${scrollbarWidth}px solid var(--color-vad-gray)`;
      if (!headerElement.classList.contains('header--static')) {
        headerElement.style.paddingRight = `${scrollbarWidth}px`;
      } else if (window.innerWidth <= 1200) {
        headerElement.style.paddingRight = `${scrollbarWidth}px`;
      }
    }
  }

  const resetHeaderPosition = (): void => {
    document.body.removeAttribute('style');
    headerElement.removeAttribute('style');
    toggleButtonElement.removeAttribute('style');
  }

  const toggleMobileMenuButton = (open: boolean): void => {
    if (open) {
      toggleButtonElement.classList.add('menu-toggle--expanded');
      toggleButtonElement.setAttribute('aria-expanded', 'true');
      toggleButtonElement.setAttribute('aria-label', toggleButtonElement?.dataset.closemenutext || '');
    } else {
      toggleButtonElement.classList.remove('menu-toggle--expanded');
      toggleButtonElement.setAttribute('aria-expanded', 'false');
      toggleButtonElement.setAttribute('aria-label', toggleButtonElement?.dataset.openmenutext || '');
    }
  }

  const initializeTrapFocus = (event: KeyboardEvent): void => {
    // Todo: Vader - Fix trapFocus
    return trapFocus(event, menuElement, document.querySelectorAll('#menu-toggle'));
  }

  const initialize = (): void => {
    if (!generalEventListenersInitialized) {
      // Close the menu when the backdrop is clicked
      document.addEventListener('click', (event: MouseEvent): void => {
        if (isOpen) {
          // Mobile
          if (window.innerWidth <= 1200) {
            closeMobileMenuOnOutsideClick(event);
          }
          // Desktop
          else {
            closeDesktopMenuOnOutsideClick(event);
          }
        }
      });

      // Close the menu when the escape key is pressed
      window.addEventListener('keyup', (event: KeyboardEvent): void => {
        if (isOpen) {
          // Mobile
          if (window.innerWidth <= 1200) {
            closeOnEsc(event, closeMobileMenu);
          }
          // Desktop
          else {
            closeOnEsc(event, toggleDesktopSubMenu);
          }
        }
      });

      generalEventListenersInitialized = true;
    }

    if (window.innerWidth <= 1200 && !mobileMenuIsInitialized) {
      // Initialize mobile menu
      initializeMobileMenu();

      // Close the desktop menu
      closeAllDesktopSubMenu();

      // Remove desktop menu event listeners
      menuButtonElements.forEach((buttonElement: HTMLButtonElement): void => {
        buttonElement.removeEventListener('click', toggleDesktopSubMenu);
      });

      desktopMenuIsInitialized = false;
    } else if (window.innerWidth > 1200 && !desktopMenuIsInitialized) {
      // Initialize desktop menu
      initializeDesktopMenu();

      // Close the mobile menu
      closeMobileMenu();
      hardCloseMobileSubMenus();

      // Remove mobile menu event listeners
      menuReturnButtonElement.removeEventListener('click', closeMobileSubMenu);
      toggleButtonElement.removeEventListener('click', toggleMobileMenuState);
      menuButtonElements.forEach((buttonElement: HTMLButtonElement): void => {
        buttonElement.removeEventListener('click', openMobileSubMenu);
      });

      mobileMenuIsInitialized = false;
    }
  }

  initialize();

  // Reinitialize the mobile menu when the window is resized
  window.addEventListener('resize', debounce(initialize, 200));
}

document.addEventListener('DOMContentLoaded', () => {
  useMenu();
});