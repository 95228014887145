const disturbance = document.querySelector('.header__disturbance');
let root = document.documentElement;

interface DisturbanceCookieData {
    closed: boolean;
    id: string;
}

function closeDisturbanceMessage() {
    disturbance?.classList.add('hidden');
    root.style.setProperty('--disturbance-height', 0 + "rem");
    const id = (disturbance?.querySelector('#disturbance-id') as HTMLInputElement).value;

    if (id != null) {
        const cookieData: DisturbanceCookieData = {
            closed: true,
            id: id
        };

        setCookie('OptimizelyFramework_disturbance', cookieData, 2); // Set the cookie to expire in 2 days
    }
}

function setCookie(name: string, value: DisturbanceCookieData, days: number) {
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    const cookieValue = encodeURIComponent(JSON.stringify(value)) + `; expires=${expires.toUTCString()}; path=/`;
    document.cookie = `${name}=${cookieValue}`;
}

if (disturbance) {
    const height = disturbance.clientHeight/10;
    root.style.setProperty('--disturbance-height', height + "rem");
    const disturbanceMessageCloseButton = disturbance.querySelector('#disturbance-message-close-button') as HTMLButtonElement;
    disturbanceMessageCloseButton.addEventListener('click', closeDisturbanceMessage);
}