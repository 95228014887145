﻿// Set global variables
if (!window.KnowitOptimizelyFramework) {
  window.KnowitOptimizelyFramework = {
    YouTube: null,
    BlockMediaScriptIsInitialized: false,
  };
}

// Imports
import "../../Features/Layout/Features/Header/Features/Menu/js/menu";
import "../../Features/Layout/Features/Header/Features/Disturbance/js/disturbance";